import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'Limit.LimitList.id',
    defaultMessage: 'Name',
  },
  maxStake: {
    id: 'Limit.LimitList.maxStake',
    defaultMessage: 'Max stake',
  },
  maxWin: {
    id: 'Limit.LimitList.maxWin',
    defaultMessage: 'Max win',
  },
  maxBet: {
    id: 'Limit.LimitList.maxBet',
    defaultMessage: 'Max bet',
  },
  maxLiability: {
    id: 'Limit.LimitList.maxLiability',
    defaultMessage: 'Max liability',
  },
  maxExposure: {
    id: 'Limit.LimitList.maxExposure',
    defaultMessage: 'Max exposure',
  },
  maxCredit: {
    id: 'Limit.LimitList.maxCredit',
    defaultMessage: 'Max credit',
  },
});
