import { FC } from 'react';
// import { InputAdornment } from '@miyagami-com/lsx-ui-components';
import {
  GridColumns,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridEventListener,
  GridEvents,
} from '@mui/x-data-grid';
import { useIntl } from 'react-intl';

// import { DEFAULT_CURRENCY, CURRENCY_SYMBOL } from '../../../common/constants';
import InputCell from '../../Unknown/InputCell';

import messages from './messages';
import DataGridTable from '../../Unknown/DataGridTable';
import { UserLimit } from '../../../../types/supabase';
import { UseQueryResult } from 'react-query';

const propertiesColumn = {
  editable: false,
  filterable: false,
  flex: 0.5,
};

export type OnUpdateLimitParams = {
  limitKey: keyof UserLimit;
  limitValue: number;
};

interface LimitListProps {
  onUpdateLimit: ({
    limitKey,
    limitValue,
  }: OnUpdateLimitParams) => Promise<void>;
  userLimitQueryLimit: UseQueryResult<UserLimit[] | null | undefined>;
  isDisabled: boolean;
}

const LimitList: FC<LimitListProps> = (params) => {
  const { userLimitQueryLimit, onUpdateLimit, isDisabled } = params;

  const { isLoading, data: limits } = userLimitQueryLimit;

  const intl = useIntl();

  const defaultRenderCell = ({
    api: dataGridApi,
    id,
    value,
    field,
  }: GridRenderCellParams | GridRenderEditCellParams) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(e.target.value);

      dataGridApi.setEditCellValue({ id, field, value: newValue });
    };

    return (
      <InputCell
        type="number"
        disabled={isDisabled}
        InputProps={{
          inputProps: {
            min: 0,
          },
          // startAdornment: (
          //   <InputAdornment position="start">
          //     {CURRENCY_SYMBOL[DEFAULT_CURRENCY]}
          //   </InputAdornment>
          // ),
        }}
        onChange={onChange}
        value={value?.toString()?.replace(/^0+./, '')}
      />
    );
  };

  const columns: GridColumns = [
    // {
    //   ...propertiesColumn,
    //   field: 'max_exposure_amount',
    //   headerName: intl.formatMessage(messages.maxExposure),
    //   renderCell: defaultRenderCell,
    //   renderEditCell: defaultRenderCell,
    // },
    // {
    //   ...propertiesColumn,
    //   field: 'max_credit_amount',
    //   headerName: intl.formatMessage(messages.maxCredit),
    //   renderCell: defaultRenderCell,
    //   renderEditCell: defaultRenderCell,
    // },
    {
      ...propertiesColumn,
      field: 'max_win_amount',
      headerName: intl.formatMessage(messages.maxWin),
      renderCell: defaultRenderCell,
      renderEditCell: defaultRenderCell,
    },
    {
      ...propertiesColumn,
      field: 'max_bet_amount',
      headerName: intl.formatMessage(messages.maxBet),
      renderCell: defaultRenderCell,
      renderEditCell: defaultRenderCell,
    },
    {
      ...propertiesColumn,
      field: 'max_liability_amount',
      headerName: intl.formatMessage(messages.maxLiability),
      renderCell: defaultRenderCell,
      renderEditCell: defaultRenderCell,
    },
  ];

  const onCellEditCommit: GridEventListener<GridEvents.cellEditCommit> = (
    cellParams,
  ) => {
    const prevValue = (cellParams as GridRenderCellParams).formattedValue;
    if (prevValue !== cellParams.value) {
      onUpdateLimit({
        limitKey: cellParams.field as keyof UserLimit,
        limitValue: Number(cellParams.value),
      });
    }
  };

  return (
    <DataGridTable
      tableKey="user-limit"
      loading={isLoading}
      columns={columns}
      rows={limits || []}
      disableSelectionOnClick
      autoHeight
      rowHeight={35}
      headerHeight={35}
      onCellEditCommit={onCellEditCommit}
      hideFooterRowCount
      pagination
    />
  );
};

export default LimitList;
